<template>
  <div>
    <b-tabs >
      <b-tab action title="Thông Tin Cá Nhân">
        <div class="ft-profile">
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                Chỉnh Sửa Trang Cá Nhân
              </b-card-title>
              <b-card-sub-title>
                <b-button variant="success">
                  <feather-icon icon="PlusIcon" />Chỉnh Sửa
                </b-button>
              </b-card-sub-title>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col cols="4">
                  <b-card
                    :img-src="require('@/assets/images/banner/banner-12.jpg')"
                    img-alt="Profile Cover Photo"
                    img-top
                    class="card-profile"
                  >
                    <div class="profile-image-wrapper">
                      <div class="profile-image p-0">
                        <b-avatar
                          size="114"
                          variant="light"
                          :src="require('@/assets/images/portrait/small/avatar-s-9.jpg')"
                        />
                      </div>
                    </div>
                    <h3>Curtis Stone</h3>
                    <h6 class="text-muted">
                      Malaysia
                    </h6>
                    <b-badge
                      class="profile-badge"
                      variant="light-primary"
                    >
                      Pro Level
                    </b-badge>
                    <hr class="mb-2">

                    <!-- follower projects rank -->
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h6 class="text-muted font-weight-bolder">
                          Followers
                        </h6>
                        <h3 class="mb-0">
                          10.3k
                        </h3>
                      </div>
                      <div>
                        <h6 class="text-muted font-weight-bolder">
                          Projects
                        </h6>
                        <h3 class="mb-0">
                          156
                        </h3>
                      </div>
                      <div>
                        <h6 class="text-muted font-weight-bolder">
                          Rank
                        </h6>
                        <h3 class="mb-0">
                          23
                        </h3>
                      </div>
                    </div>
                    <!--/ follower projects rank -->
                  </b-card>
                </b-col>
                <b-col>
                  <info :form="form" />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
      </b-tab>
      <b-tab title="Quản Lý Mật Khẩu">
        <b-row>
          <b-col cols="6">
            <Password :form="form" />
          </b-col>
        </b-row>
      </b-tab>
  </b-tabs>
  </div>
</template>

<script>
import { BAvatar, BFormInput, BTab, BTabs, BCol, BRow, BButton, BCard, BCardBody, BCardTitle, BCardSubTitle, BCardHeader } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Info from './staffs/components/Info.vue'
import Password from './components/Password.vue'

export default {
  data () {
    return {
      form: {}
    }
  },
  components: {
    BAvatar,
    BButton,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCol,
    BRow,
    BTab,
    BTabs,
    BFormInput,
    FeatherIcon,
    Info,
    Password
}
}
</script>

<style>

</style>