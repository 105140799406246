<template>
  <app-collapse accordion>
    <app-collapse-item
      :isVisible="true"
      headerBgVariant="secondary"
    >
      <template #header>
        <b-card-title class="text-white"><feather-icon icon="AlertCircleIcon" /> Thay Đổi Mật Khẩu</b-card-title>
      </template>
      <div style="padding: 20px">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Mật Khẩu Hiện Tại"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="currentPassword"
                rules="required"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-form-input
                  type="password"
                  v-model="form.currentPassword"
                  :state="errors.length > 0 ? false:null"
                  @change="onChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Mật Khẩu Mới"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="newPassword"
                rules="required"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-form-input
                  type="password"
                  v-model="form.newPassword"
                  :state="errors.length > 0 ? false:null"
                  @change="onChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Nhập Lại Mật Khẩu Mới"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="reNewPassword"
                rules="required"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-form-input
                  type="password"
                  v-model="form.reNewPassword"
                  :state="errors.length > 0 ? false:null"
                  @change="onChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="3"
            >
              <b-button variant="success">Cập Nhật</b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import constants from '@/@core/app-config/constants'
import {
  BFormDatePicker, BInputGroup, BInputGroupPrepend, BCardBody, BCardTitle, BCardSubTitle, BCardHeader, BFormInput, BFormDatepicker, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

import PcVn from 'pc-vn'
import CountryCode from '@/@core/app-config/countryCode'

export default {
  data() {
    return {
      address: {
        provinces: [{ text: 'Tỉnh / Thành', value: null }],
        districts: [{ text: 'Quận / Huyện', value: null }],
        wards: [{ text: 'Thị xã', value: null }],
      },
    }
  },
  methods: {
    selectedProvince(e) {
      const list = PcVn.getDistrictsByProvinceCode(e)
      this.form.district = null// về mặc đinh
      this.address.districts = [...this.address.districts, ...list.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
      console.log('change selectedProvince', this.address.districts)
    },
    onChange() {
      console.log('onChange');
    },
    selectedDistrict(e) {
      const list = PcVn.getWardsByDistrictCode(e)
      this.form.ward = null// về mặc đinh
      this.address.wards = [this.address.wards[0], ...list.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
    },
  },
  computed: {
    genderOptions() {
      console.log('constants.genderOptions', constants.genderOptions);
      return constants.genderOptions
    },
  },
  props: {
    form: {
      type: Object,
    }
  },

  components: {
    BFormDatePicker,
    BInputGroup,
    BInputGroupPrepend,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    FeatherIcon,
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    countryCode() {
      return CountryCode.map((item, k) => {
        return {
          text: item.name,
          value: item.code,
        }
      })
    },
    getProvinces() {
      const provinces = PcVn.getProvinces()
      return [this.address.provinces[0], ...provinces.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
    },
  },
}
</script>
